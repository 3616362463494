export const environment = {
    production: true,
    banorte:false,
    ente: "H. Ayuntamiento de Sinaloa",
    administracion: "2021 - 2024",
    url_escudo: "../assets/sinaloa/escudo.jpg",
    url_administracion: "../assets/sinaloa/logo_administracion.jpg",
    url_footer: "../assets/sinaloa/escudo.jpg",
    direcciones: [
        " Calle Miguel Hidalgo y Morelos s/n",
        "C.P. 81910 ",
        "Sinaloa, Sinaloa"
    ],
    telefonos: [
        "687 87 50339"
    ],
    emails: [
        "presidencia@sinaloa.gob.mx",
        "tesoreriamunsin@hotmail.com "
    ],
    url_aviso: "http://sinaloa.gob.mx/cms/informacion-historica/",
    url_webapi: "https://pagossinaloa.azurewebsites.net/api/",
    url_sitio: "https://pagossinaloa.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "004",
    municipio: "SINALOA",
    firebase: {
        apiKey: "AIzaSyDAkpLF3S5iI1UME1sE33OEu7RlTrU4xuI",
        authDomain: "pagossinaloa-db7c8.firebaseapp.com",
        projectId: "pagossinaloa-db7c8",
        storageBucket: "pagossinaloa-db7c8.appspot.com",
        messagingSenderId: "989398033162",
        appId: "1:989398033162:web:16b1794a20cfd8823bd0f3",
        measurementId: "G-9J3K2S5KYL"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de Sinaloa para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "1234",
    llave: "-v0aeT5E1AtAelqxsjcG",
    img_ayuda_1: '../assets/sinaloa/img_ayuda_1.jpg',
    img_ayuda_2: '../assets/sinaloa/img_ayuda_2.jpg',
    img_ayuda_3: '../assets/sinaloa/img_ayuda_3.jpg'
  };